import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ClaimCheck from './components/ClaimCheck';
import UserClaimCheck from './components/UserClaimCheck';

const App = () => {
  return (
      <Router>
        <Routes>
          <Route path="/admin/claimCheck" element={<ClaimCheck />} />
          <Route path="/user/claimCheck" element={<UserClaimCheck />} />
        </Routes>
      </Router>
  );
};

export default App;
