import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Box, Button, Container, Paper, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const UserClaimCheck = () => {
    const [address, setAddress] = useState('');
    const [poolData, setPoolData] = useState([]); // Store the entire data
    const [filteredData, setFilteredData] = useState([]); // Store filtered data
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const chainId = searchParams.get('chainId');
        const contractAddress = searchParams.get('contractAddress');
        const tokenContractAddress = searchParams.get('tokenContractAddress');

        axios.get('/poolUploadSummary.json').then((response) => {
            if (response.data.identifiers.chainId === chainId &&
                response.data.identifiers.contractAddress === contractAddress &&
                response.data.identifiers.tokenContractAddress === tokenContractAddress) {
                const dataWithLowercaseAddresses = response.data.poolSummary.map(item => ({
                    ...item,
                    address: item.address.toLowerCase()
                }));
                setPoolData(dataWithLowercaseAddresses); // Store the entire data
            }
        });
    }, [location]);

    const handleSearch = () => {
        const lowercasedAddress = address.toLowerCase();
        const data = poolData.filter((item) => item.address === lowercasedAddress);
        setFilteredData(data); // Update only the filtered data

        if (data.length === 0) {
            toast.info('No data found for this address.');
        }
    };

    return (
        <Box>
            <Container component="main" maxWidth="xs">
                <Paper sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }} elevation={3}>
                    <Typography component="h1" variant="h5">
                        User Claim Check
                    </Typography>
                    <TextField
                        fullWidth
                        label="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        margin="normal"
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Paper>
            </Container>
            {filteredData.length > 0 && (
                <Container component="main" maxWidth="lg" sx={{ mt: 4 }}>
                    <TableContainer component={Paper} elevation={3}>
                        <Table sx={{ minWidth: '75%' }} aria-label="results table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Pool Name</TableCell>
                                    <TableCell>Original Allocation</TableCell>
                                    <TableCell>Claimed To Date</TableCell>
                                    <TableCell>Remaining Allocation</TableCell>
                                    <TableCell>Total Claimable at Snapshot</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row['Pool Name']}</TableCell>
                                        <TableCell>{row['Original Allocation']}</TableCell>
                                        <TableCell>{row['Claimed To Date']}</TableCell>
                                        <TableCell>{row['Remaining Allocation']}</TableCell>
                                        <TableCell>{row['Total Claimable at Snapshot']}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            )}
        </Box>
    );
};

export default UserClaimCheck;
