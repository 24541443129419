// src/components/ClaimCheck.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Container, MenuItem, Paper, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Web3 from 'web3';
import IronVestABI from '../ABI/IronVestABI.json';
// import ERC20ABI from '../ABI/ERC20ABI.json';

const ClaimCheck = () => {
    const [chainId, setChainId] = useState('');
    const [contractAddress, setContractAddress] = useState('');
    const [tokenContractAddress, setTokenContractAddress] = useState('');
    const [vestingType, setVestingType] = useState('Cliff Vesting');
    const [blockNumber, setBlockNumber] = useState('');
    const [poolId, setPoolId] = useState('');
    const [address, setAddress] = useState('');
    const [originalAllocation, setOriginalAllocation] = useState(null);
    const [claimedToDate, setClaimedToDate] = useState(null);
    const [cliffClaimable, setCliffClaimable] = useState(null);
    const [nonCliffClaimable, setNonCliffClaimable] = useState(null);
    const [userInfo, setUserInfo] = useState({ cliffInfo: {}, nonCliffInfo: {} });
    const [totals, setTotals] = useState({ remainingAllocation: 0, totalClaimableSnapshot: 0 });
    // const [tokenDecimals, setTokenDecimals] = useState(18);
    const scale = Math.pow(10, 3); // Scaling factor for 3 decimal places

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setChainId(searchParams.get('chainId') || '');
        setContractAddress(searchParams.get('contractAddress') || '');
        setTokenContractAddress(searchParams.get('tokenContractAddress') || '');
        setBlockNumber(searchParams.get('blockNumber') || '');
        setPoolId(searchParams.get('poolId') || '');
        setAddress(searchParams.get('address') || '');
        const urlVestingType = searchParams.get('vestingType');
        if (urlVestingType === 'Simple Vesting' || urlVestingType === 'Cliff Vesting') {
            setVestingType(urlVestingType);
        }
    }, [location]);

    useEffect(() => {
        if (userInfo.cliffInfo && userInfo.nonCliffInfo) {
            updateTotals();
        }
    }, [userInfo, cliffClaimable, nonCliffClaimable]);

    const rpcUrls = {
        '1': process.env.REACT_APP_RPC_URL_1,
        '56': process.env.REACT_APP_RPC_URL_56,
        '137': process.env.REACT_APP_RPC_URL_137,
        '42161': process.env.REACT_APP_RPC_URL_42161
    };

    const updateTotals = () => {
        const web3 = new Web3();
        const cliffRemaining = convertToDecimal(userInfo.cliffInfo['remainingToBeClaimableCliff'], web3, scale);
        const nonCliffRemaining = convertToDecimal(userInfo.nonCliffInfo['remainingToBeClaimableNonCliff'], web3, scale);
        const remainingAllocation = cliffRemaining + nonCliffRemaining;
        const totalClaimableSnapshot = parseFloat(cliffClaimable) + parseFloat(nonCliffClaimable);

        setTotals({ remainingAllocation, totalClaimableSnapshot });
    };

    const convertToDecimal = (weiValue, web3, scale) => {
        return Math.floor(parseFloat(web3.utils.fromWei(weiValue || '0', 'ether')) * scale) / scale;
    };

    const handleGetClaimData = async () => {
        toast.info("Processing request...", { autoClose: 2000 });
        // Resetting the state variables before fetching new data
        setOriginalAllocation(null);
        setClaimedToDate(null);
        setCliffClaimable(null);
        setNonCliffClaimable(null);
        // setVestingType(null);
        setUserInfo({ cliffInfo: {}, nonCliffInfo: {}, userInfo: {} }); // Include userInfo
        setTotals({ remainingAllocation: 0, totalClaimableSnapshot: 0 });

        if (!rpcUrls[chainId]) {
            toast.error("This chainId is not supported yet. Contact Support: https://help.ferrumnetwork.io/en/");
            return;
        }

        try {
            const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrls[chainId]));
            const contract = new web3.eth.Contract(IronVestABI, contractAddress);

            // Fetch data based on vesting type
            if (vestingType === 'Cliff Vesting') {
                await fetchUserInfo(web3, contract, poolId, address, blockNumber, vestingType); // Pass vestingType
                await fetchClaimableAmounts(web3, contract, poolId, address, blockNumber);
            } else {
                // For Simple Vesting, only call fetchUserInfo
                await fetchUserInfo(web3, contract, poolId, address, blockNumber, vestingType); // Pass vestingType
            }

            toast.success("Results successfully received!");
        } catch (error) {
            console.error(error);
            toast.error("Error fetching data. Please check input values.");
        }
    };


    const fetchUserInfo = async (web3, contract, poolId, address, blockNumber) => {
        try {
            if (vestingType === 'Cliff Vesting') {
                const cliffInfo = await contract.methods.userCliffInfo(poolId, address).call(null, blockNumber);
                const nonCliffInfo = await contract.methods.userNonCliffInfo(poolId, address).call(null, blockNumber);

                // Set userInfo state for Cliff Vesting
                setUserInfo({ cliffInfo, nonCliffInfo });

                // Update originalAllocation and claimedToDate for Cliff Vesting
                const allocation = cliffInfo.allocation || nonCliffInfo.allocation;
                const claimedAmountCliff = BigInt(cliffInfo.claimedAmnt) || BigInt(0);
                const claimedAmountNonCliff = BigInt(nonCliffInfo.claimedAmnt) || BigInt(0);

                setOriginalAllocation(convertToDecimal(allocation, web3, scale).toString());
                setClaimedToDate(convertToDecimal(claimedAmountCliff + claimedAmountNonCliff, web3, scale).toString());

            } else {
                // Logic for Simple Vesting
                const userInfo = await contract.methods.userInfo(poolId, address).call(null, blockNumber);
                const claimableAmount = await contract.methods.claimable(poolId, address).call(null, blockNumber);

                // Set userInfo state for Simple Vesting
                setUserInfo({ userInfo });

                // Update originalAllocation and claimedToDate for Simple Vesting
                setOriginalAllocation(convertToDecimal(userInfo.allocation, web3, scale).toString());
                setClaimedToDate(convertToDecimal(userInfo.claimedAmount, web3, scale).toString());
                setTotals({
                    remainingAllocation: convertToDecimal(userInfo.remainingToBeClaimable, web3, scale).toString(),
                    totalClaimableSnapshot: convertToDecimal(claimableAmount, web3, scale).toString()
                });
            }
        } catch (error) {
            console.error('Error fetching user info:', error);
            toast.error("Error fetching user info. Please check input values.");
        }
    };

    const fetchClaimableAmounts = async (web3, contract, poolId, address, blockNumber) => {
        const cliffAmountWei = await contract.methods.cliffClaimable(poolId, address).call(null, blockNumber);
        const nonCliffAmountWei = await contract.methods.nonCliffClaimable(poolId, address).call(null, blockNumber);
        setCliffClaimable(convertToDecimal(cliffAmountWei, web3, scale).toString());
        setNonCliffClaimable(convertToDecimal(nonCliffAmountWei, web3, scale).toString());
    };


    return (
        <Box>
            <Container component="main" maxWidth="xs">
                <Paper sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }} elevation={3}>
                    <Typography component="h1" variant="h5">
                        Claim Check
                    </Typography>
                    <Box component="form" sx={{ mt: 1 }}>
                        <TextField
                            fullWidth
                            label="Chain ID"
                            value={chainId}
                            onChange={(e) => setChainId(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Contract Address"
                            value={contractAddress}
                            onChange={(e) => setContractAddress(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Token Contract Address"
                            value={tokenContractAddress}
                            onChange={(e) => setTokenContractAddress(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Block Number"
                            value={blockNumber}
                            onChange={(e) => setBlockNumber(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            select
                            label="Vesting Type"
                            value={vestingType}
                            onChange={(e) => setVestingType(e.target.value)}
                            margin="normal"
                            fullWidth
                        >
                            <MenuItem value="Cliff Vesting">Cliff Vesting</MenuItem>
                            <MenuItem value="Simple Vesting">Simple Vesting</MenuItem>
                        </TextField>
                        <TextField
                            fullWidth
                            label="Pool ID"
                            value={poolId}
                            onChange={(e) => setPoolId(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            margin="normal"
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleGetClaimData}
                        >
                            Get Claimable Data
                        </Button>
                        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                    </Box>
                </Paper>
            </Container>
            {/* Results Table Container */}
            <Container component="main" maxWidth="lg" sx={{ mt: 4 }}>
                <TableContainer component={Paper} elevation={3}>
                    <Table sx={{ minWidth: '75%' }} aria-label="results table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Original Allocation</TableCell>
                                <TableCell>Claimed To Date</TableCell>
                                <TableCell>Remaining Allocation</TableCell>
                                <TableCell>Total Claimable at Snapshot</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{originalAllocation}</TableCell>
                                <TableCell>{claimedToDate}</TableCell>
                                <TableCell>{totals.remainingAllocation}</TableCell>
                                <TableCell>{totals.totalClaimableSnapshot}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </Box>
    );
};

export default ClaimCheck;